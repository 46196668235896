import React, { useEffect } from "react"
import { useRouter } from "next/router"
import { setUser } from "@sentry/nextjs"
import Head from "next/head"
import { AppProps } from "next/app"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { CacheProvider } from "@emotion/react"
import { ApolloProvider } from "@apollo/client"
import CssBaseline from "@mui/material/CssBaseline"
import createCache from "@emotion/cache"
import theme from "../theme/theme"
import { useApollo } from "client/apollo"
import { SnackbarProvider } from "components/useSnackbars"
import { useAnalytics } from "components/useAnalytics"
import { useUser } from "components/useUser"
import "../i18n"

export const cache = createCache({
  key: "css",
  prepend: true,
})

const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isLoading } = useUser({})
  const analytics = useAnalytics()
  const router = useRouter()

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user) {
      setUser(null)
      return
    }

    setUser({ id: user.id, email: user.email ?? undefined })
  }, [isLoading, user?.email, user?.id])

  useEffect(() => {
    analytics.page()
  }, [router.pathname, JSON.stringify(router.query)])

  return <>{children}</>
}

const MyApp: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props
  const apolloClient = useApollo(pageProps.initialApolloState)

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    jssStyles?.parentElement?.removeChild(jssStyles)
  }, [])

  return (
    <CacheProvider value={cache}>
      <Head>
        <title>The Modern Driving School | 🚗 Coastline Academy</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <SnackbarProvider>
              <Root>
                <CssBaseline />
                {React.createElement(Component, pageProps)}
              </Root>
            </SnackbarProvider>
          </ApolloProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  )
}

export default MyApp
