import { createTheme } from "@mui/material/styles"
import { PaletteOptions } from "@mui/material/styles/createPalette"

const basePalette: PaletteOptions = {
  primary: {
    main: "#1E2631",
  },
  secondary: {
    main: "#0BD1C2",
    dark: "#077068",
    contrastText: "#F7F7F7",
  },
  accentOne: {
    main: "#FF70A6",
  },
  accentTwo: {
    main: "#9EF62E",
  },
  accentThree: {
    main: "#E00056",
  },
  text: {
    primary: "#222222",
  },
  backgroundSecondary: {
    main: "#F7F7F7",
  },
}

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  palette: basePalette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#0BD1C2",
            color: "#1E2631",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
})

export default theme
