import { GQLDrivingSkillRating, GQLWeekDays } from "generated/graphqlTypes"

export const DEFAULT_QUERY_PAGE_SIZE = 40
export const DEFAULT_TABLE_ITEMS_PER_PAGE = 20
export const TABLE_ITEMS_PER_PAGE = [10, 20, 40]

export enum ErrorCode {
  NotSignedIn = "NOT_SIGNED_IN",
  WrongCredentials = "WRONG_CREDENTIALS",
  InvalidEmail = "INVALID_EMAIL",
  NotFound = "NOT_FOUND",
  BadRequest = "BAD_REQUEST",
  Conflict = "CONFLICT",
  VoucerInactive = "VOUCER_INACTIVE",
  VoucherRedeemed = "VOUCHER_REDEEMED",
  LedgerOutOfSync = "LEDGER_OUT_OF_SYNC",
  NotEnoughProduct = "NOT_ENOUGH_PRODUCT",
  NotImplemented = "NOT_IMPLEMENTED",
}

// If this is changes
// the GQL type needs to be changes also
export const COLLECTIONS = [
  "instructorAvailsV2",
  "instructorReviewsV2",
  "instructors",
  "lessonSlotsV2",
  "users",
  "vehicles",
  "vouchers",
  "waitlist",
]

// Like Dec 29, 2020, 12:15pm
export const DEFAULT_LONG_DATE_TIME_FMT = "MMM d, yyyy, h:mmaaa"
export const LONG_DATE_TIME_TIMEZONE_FMT = "MMMM d, yyyy, h:mmaaa z"
export const DEFAULT_DATE_FMT = "MMM d, yyyy"
export const LEGACY_DATE_FMT = "yyyy-MM-dd"
export const DEFAULT_DATE_WEEK_DAY = "eeee, MMM d"
export const DEFAULT_TIME_OF_DAY_24_HOURS_FMT = "HH:mm"
export const DEFAULT_TIME_OF_DAY_FMT = "p"

export const MAX_PORTRAIT_IMG_SIZE_BYTES = 2 * 10 ** 6 // 2Mb
export const MAX_REGION_IMPORT_SIZE_BYTES = 2 * 10 ** 6 // 2Mb

export const DEFAULT_TIME_ZONE = "America/Los_Angeles"

export const TIME_ZONES = ["America/Los_Angeles"]

export const CALENDAR_LESSON_COLOR = "rgb(33, 150, 243)"
export const CALENDAR_AVAILABILITY_COLOR = "rgb(100, 181, 246)"
export const CALENDAR_PRIVATE_AVAILABILITY_COLOR = "rgb(255, 64, 129)"
export const CALENDAR_TRANSIENT_SLOT_COLOR = "rgb(255, 152, 0)"

export const EPOCH = new Date("2010-01-01")

export const OLD_BG_COLOR = "#fdb8c0"
export const NEW_BG_COLOR = "#acf2bd"
export const CHANGE_PADDING = 3

export const WEEK_DAYS: Array<GQLWeekDays> = [
  GQLWeekDays.Sunday,
  GQLWeekDays.Monday,
  GQLWeekDays.Tuesday,
  GQLWeekDays.Wednesday,
  GQLWeekDays.Thursday,
  GQLWeekDays.Friday,
  GQLWeekDays.Saturday,
]

export const DEFAULT_TEST_STUDENT_ID = "student-id"

export const DRIVING_SKILL_RATING_TO_VALUE_MAP: { [x: string]: number } = {
  [GQLDrivingSkillRating.Todo]: 1,
  [GQLDrivingSkillRating.NeedsAdditionalDevelopment]: 2,
  [GQLDrivingSkillRating.NeedsImprovement]: 3,
  [GQLDrivingSkillRating.Consistent]: 4,
  [GQLDrivingSkillRating.Capable]: 5,
  [GQLDrivingSkillRating.Proficient]: 6,
}

export const DRIVING_SKILL_VALUE_TO_RATING_MAP: {
  [x: number]: GQLDrivingSkillRating
} = {
  1: GQLDrivingSkillRating.Todo,
  2: GQLDrivingSkillRating.NeedsAdditionalDevelopment,
  3: GQLDrivingSkillRating.NeedsImprovement,
  4: GQLDrivingSkillRating.Consistent,
  5: GQLDrivingSkillRating.Capable,
  6: GQLDrivingSkillRating.Proficient,
}
